/**
 * 封装 axios 请求模块
 * 接口开头全部小写
 * 增 add**
 * 删 delete**
 * 改 updata**
 * 查 get**
 */

import axios from "axios"
import common from "../assets/common"
import store from '../store/index'
import router from '../router/index'
import { MessageBox } from 'element-ui';

const request = axios.create({
    baseURL: process.env.VUE_APP_URL,
    // withCredentials:true,
})

import jsCookie from 'js-cookie'
const cookies = jsCookie; 

//  axios.interception.request.user(config => {
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   return config
// })
// request拦截器
request.interceptors.request.use(config => {
    //接口携带token
    config.headers['x-h5-session-id'] = cookies.get('SID')
    config.headers['x-h5-session-id'] = '72836b29d2e344a1a5238d6cb1d87a96';
    return config
}, error => {
    Promise.reject(error)
})

let flag = 0
request.interceptors.response.use(response => {
    const code = response.data.code || 200
    store.commit('setToken', response.headers['new-token'])

    if(code == 4001) {
        flag++

        if(flag == 1) {
            debugger
            // router.replace({
            //   path:'/login'
            // })
            MessageBox('登录状态已过期，请重新登录?', '系统提示', {
                confirmButtonText: '确定',
                type: 'warning'

            }).then(() => {

                router.replace({
                    path: '/login'
                })
                flag = 0

                return response;
            }).catch(err => { });
        }


    } else {
        return response;
    }



}, error => {

    return Promise.reject(error);
})





export default request




function comfirm() {


}